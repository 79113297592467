import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { styles } from "../utilities/styles";

const componentStyles = {
  primaryColor: {
    Mountainland: "bg-yellow",
    Wasatch: "bg-green",
  },
};

export const OurProcess = () => {
  return (
    <>
      <h2 className={classNames(styles.h2)}>Our Process</h2>
      <div className="grid md:grid-cols-3 gap-4 lg:gap-6 mt-8">
        <div className="bg-grey-lighter rounded-xl p-4 lg:px-8 lg:pb-8 lg:pt-4">
          <div className="mb-4 relative">
            <span className="text-4xl relative z-20 ml-3">1</span>
            <span
              className={`
            ${
              componentStyles.primaryColor[process.env.COMPANY_NAME_SHORTER]
            } w-10 h-10 rounded-full block absolute top-6 z-10
          `}
            ></span>
          </div>

          <StaticImage
            className="w-full h-48 rounded-lg mb-4"
            src="../images/home-page/installation-measure.jpg"
            alt="Measuring a window well for a cover"
            objectFit="cover"
            objectPosition="right bottom"
          />
          <h3 className="text-default text-lg md:text-xl bold mb-2 text-grey-darkest">
            We measure your window well
          </h3>
          <p className="leading-loose text-grey-darker">
            Our technician will visit your location, equipped with precise
            measuring tools and equipment, to accurately measure your window
            wells.
          </p>
        </div>
        <div className="bg-grey-lighter rounded-xl p-4 lg:px-8 lg:pb-8 lg:pt-4">
          <div className="mb-4 relative">
            <span className="text-4xl relative z-20 ml-3">2</span>
            <span
              className={`
            ${
              componentStyles.primaryColor[process.env.COMPANY_NAME_SHORTER]
            } w-10 h-10 rounded-full block absolute top-6 z-10
          `}
            ></span>
          </div>

          <StaticImage
            className="w-full h-48 rounded-lg mb-4"
            src="../images/head-down-welding.jpg"
            alt="Fabricating a window well cover"
            objectFit="cover"
            objectPosition="center center"
          />
          <h3 className="text-default text-lg md:text-xl bold mb-2 text-grey-darkest">
            We custom build your window well covers
          </h3>
          <p className="leading-loose text-grey-darker">
            Our production team will custom-build your covers with precise
            measurements and high-quality materials for a perfect, durable fit.
          </p>
        </div>

        <div className="bg-grey-lighter rounded-xl p-4 lg:px-8 lg:pb-8 lg:pt-4">
          <div className="mb-4 relative">
            <span className="text-4xl relative z-20 ml-3">3</span>
            <span
              className={`
            ${
              componentStyles.primaryColor[process.env.COMPANY_NAME_SHORTER]
            } w-10 h-10 rounded-full block absolute top-6 z-10
          `}
            ></span>
          </div>

          <StaticImage
            className="w-full h-48 rounded-lg mb-4"
            src="../images/installation-trimmed.jpg"
            alt="Installing a window well cover"
            objectFit="cover"
            objectPosition="center center"
          />
          <h3 className="text-default text-lg md:text-xl bold mb-2 text-grey-darkest">
            We come back to install your window well covers
          </h3>
          <p className="leading-loose text-grey-darker">
            Our technician will deliver and install your window well covers
            hassle-free.
          </p>
        </div>
      </div>
    </>
  );
};
